<template>
  <div id="top-control">
    <div class="container clear-fix">
      <div class="leftContainer">
        <img src="~@/assets/img/kobe.jpg" alt="" />
        <div class="mid">
          <ul class="clear-fix">
            <li v-for="item in topItems" :key="item">
              <top-control-item :title="item" :path="path(item)"
                @rightContainerShowAndHidden="rightContainerShowAndHidden">
              </top-control-item>
            </li>
          </ul>
          <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true" @click="writeBlog"></i>
        </div>
      </div>
      <div class="rightContainer" v-show="rightShow">
        <div class="mid right">
          <ul class="clear-fix rightCategory">
            <li v-for="(item,index) in category" :key="item">
              <top-control-item :title="item" :index=index :current-index="currentIndex" @itemClick="itemClick">
              </top-control-item>
            </li>
          </ul>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
  import TopControlItem from "./TopControlItem.vue";
  import "font-awesome/css/font-awesome.min.css";

  export default {
    name: "topControl",
    data() {
      return {
        topItems: ["博客", "个人简介"],
        category: ["全部", "iOS", "JavaScript", "Java", "Flutter", "前端"],
        rightShow: true,
        currentIndex: 0
      };
    },
    computed: {
      path() {
        return (item) => {
          if (item == "博客") {
            return "/blog/blogContent";
          } else if (item == "个人简介") {
            return "/blog/personal";
          }
        };
      },
    },
    methods: {
      itemClick(index) {
        this.currentIndex = index;
        // 这里需要把事件传递到兄弟组件BlogContent中，采用事件总线吧
        this.$bus.$emit('filterContent', this.category[index])
      },
      rightContainerShowAndHidden(rightShow) {
        console.log(rightShow)
        this.rightShow = rightShow;
        console.log(this.rightShow)
      },
      writeBlog() {
        this.$router.push('/writeblog')
      },
      watchWindowSize() {
        var el = document.getElementsByTagName('i');
        if (el) {
          if (document.documentElement.clientWidth <= 750) {
            el[0].classList.remove('fa-2x');
          } else {
            el[0].classList.add('fa-2x');
          }
          console.log(el[0].classList);
        }
      }
    },
    mounted() {
      this.watchWindowSize();
      window.addEventListener("resize", this.watchWindowSize);
    },
    destroyed() {
      window.removeEventListener("resize", this.watchWindowSize);
    },
    components: {
      TopControlItem,
    },
  };
</script>

<style lang='less' scoped>
  #top-control {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 99999999;
    height: 60px;
  }

  img {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    margin: 5px 40px;
  }

  li {
    float: left;
    margin-right: 20px;
  }

  li:hover {
    cursor: pointer;
  }

  // 这里没有采用flex布局，直接采用浮动平分父元素的宽度
  .leftContainer {
    float: left;
    position: relative;
    width: 50%;
    height: 100%;
  }

  // 当子元素没有设置具体的高度的时候，垂直居中可以采用transform移动自身高度的一半
  // position: absolute;
  // top: 50%;
  // right: 0;
  // transform: translate(0, -50%);
  .rightContainer {
    float: right;
    width: 50%;
    height: 100%;
  }

  .container {
    position: relative;
  }

  .mid {
    position: absolute;
    // 居中不太好弄，就用flex吧
    display: inline-flex;
    align-items: center;
    top: 50%;
    transform: translate(0, -50%); // 让ul在父元素中垂直居中对齐
  }

  .right {
    right: 10%;
  }

  @media (max-width: 750px) {
    #top-control {
      padding-top: 10px;
      height: 30px;
    }

    .right {
      right: 0%;
    }

    img {
      width: 25px;
      height: 25px;
      border-radius: 12.5px;
    }


  }
</style>